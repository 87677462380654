
  .animation {
    height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    background: black;
    color: aliceblue;
    cursor: pointer;
  }
  .webgl {
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    z-index: 99;
    touch-action: none;
  }
  
  .lil-gui {
    z-index: 2 !important; /* TODO Solve this in HTML */
  }
  
  @media all and (max-width: 640px) {
    .lil-gui.root {
      right: auto;
      top: auto;
      max-height: 50%;
      max-width: 80%;
      bottom: 0;
      left: 0;
    }
  }

  
  