.screen-center{
    position: relative;
    top: 50%;
    left: 50%;
    padding-top: 150px;
    padding-bottom: 150px;
}

/* Importing custom fonts */

@font-face {
    font-family: 'CabinetGrotesk-Medium';
    src: url(./fonts/CabinetGrotesk-Medium.woff) ;
}

@font-face {
    font-family: 'CabinetGrotesk-Regular';
    src: url(./fonts/CabinetGrotesk-Regular.woff) ;
}

